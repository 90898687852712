import { getDefaultProvider } from 'ethers'

import { Config } from './Config'

export function getRopstenConfig (): Config {
  return {
    apiUrl: 'https://ropsten-api.original.works',
    network: 'ropsten',
    provider: getDefaultProvider('ropsten'),
    nativeCoin: 'ETH',
    OWNTokenAddress: '0xbD01BeB8Ac8A03F144F12A6E38F3Cb73B578F8Ba',
    cashoutAddress: '0x0000000000000000000000000000000000000001',
    factoryAddress: '0x555D9153093c24e0De2D8512AaDE97BD78FCD2b6',
    feeManagerAddress: '0xa1648AEf277a28ceC23f429A636347D8e3BFF372',
    campaignId: 5,
    // eslint-disable-next-line max-len
    campaignApiKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuc3RhZ2luZyIsInN1YiI6IjUiLCJpYXQiOjE1Njk4MzIwNjYsImp0aSI6IjE1Njk4MzIwNjYxIiwic2NvcGVzIjpbInJlZGVlbTpjcmVhdGUiXX0.vKEsCbFGcjL5ShajIVXOpQL1gd6CWlvwab2gjPXqrpM',
    stripeKey: 'pk_test_4DmGPmpaGOHr92X4uL2IxoKc',
  }
}
