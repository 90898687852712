import { providers } from 'ethers'

import { Config } from './Config'

export function getOwnetConfig (): Config {
  return {
    apiUrl: 'https://original-works-ownet-api.herokuapp.com',
    network: 'ownet',
    provider: new providers.JsonRpcProvider(OWNET_URL),
    nativeCoin: 'OWN',
    OWNTokenAddress: '0xa1Fe1C133Df127D8c2489256015329434C048396',
    cashoutAddress: '0x0000000000000000000000000000000000000001',
    factoryAddress: '0x521043c1b3e4602BCdf86a473fA4Df505caEA4E2',
    feeManagerAddress: '0xeD26574F1ef77c3b26fbE448aef276E258E573DA',
    campaignId: 1,
    // eslint-disable-next-line max-len
    campaignApiKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkucHJvZHVjdGlvbiIsInN1YiI6IjEiLCJpYXQiOjE1Njk4MzI1NzcsImp0aSI6IjE1Njk4MzI1NzcxIiwic2NvcGVzIjpbInJlZGVlbTpjcmVhdGUiXX0.DAg0cJHpJ8w3pTJqL-ve8yM1IgLd3MeMyvfd3LbJSyA',
    stripeKey: 'pk_test_4DmGPmpaGOHr92X4uL2IxoKc',
  }
}

const OWNET_URL = 'https://transaction-node.ownqbs.ownconsortium.onquorum.net:3200/gzAu2ntD4_qdi8j4-fYAn-6g'
