import { Wallet } from 'ethers'
import { Dayjs } from 'dayjs'
import { cast } from '@restless/sanitizers'

import { CurrencyValue } from 'original-works-core/utils'
import {
  IApi,
  ApiUserData,
  ApiAgreement,
  ApiContract,
  ApiFinancialFactsheet,
  asApiFinancialFactsheet,
  ETHAddress,
  TransactionHash,
  Session,
  SessionUser,
  ApiAssetContract,
  ApiAgreementContract,
  Loan,
} from 'original-works-core/models'

async function getResponseValue (res: Response) {
  return res.text().then((text) => {
    try {
      return JSON.parse(text)
    } catch (e) {
      return text
    }
  })
}

export async function handleApiResponse (res: Response) {
  if (res.ok) {
    return getResponseValue(res)
  }
  return getResponseValue(res)
    .then(value => Promise.reject(value))
}

export class Api implements IApi {
  constructor (private apiUrl: string) {
  }

  async getExchangeRates () {
    return this.fetchFromApi('/exchange-rates')
  }

  async getAgreementsFor (address: string): Promise<ApiAgreement[]> {
    return this.fetchFromApi(`/agreements?holder=${address}`)
  }

  async createSession (): Promise<Session> {
    return this.fetchFromApi('/session-connections', {
      method: 'POST',
    })
  }

  async getSession (sessionId: string): Promise<SessionUser> {
    return this.fetchFromApi(`/session-connections/${sessionId}`, {
      credentials: 'include',
    })
  }

  async isAdmin (address: string): Promise<boolean> {
    return this.fetchFromApi(`/users/${address}/isAdmin`, {
      credentials: 'include',
    }).then(({ isAdmin }) => isAdmin)
  }

  async saveAgreementData (data: any): Promise<string> {
    return this.fetchFromApi('/agreements/data', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(responseData => responseData.id)
  }

  async getAgreementProperties (id: string) {
    return this.fetchFromApi(`/agreements/data/${id}`)
  }

  async getHolders (agreement: string): Promise<{
    address: string,
    balance: string,
    isAdmin: boolean,
  }[]> {
    return this.fetchFromApi(`/agreements/${agreement}/holders`)
  }

  async getContractFinancials (address: string): Promise<ApiFinancialFactsheet> {
    return this.fetchFromApi(`/contracts/${address}/financials`)
      .then(data => cast(data, asApiFinancialFactsheet))
  }

  async getTransfers (agreement: string): Promise<{
    from: string,
    to: string,
    value: string,
    timestamp: number,
    transactionHash: string,
  }[]> {
    return this.fetchFromApi(`/agreements/${agreement}/transfers`)
      .then((response) => response.reverse())
  }

  async getTransfersToContract (address: string): Promise<{
    from: string,
    value: string,
    timestamp: number,
    transactionHash: string,
  }[]> {
    return this.fetchFromApi(`/contracts/${address}/transfers-to`)
  }

  async getCashouts () {
    return this.fetchFromApi('/cashouts', {
      credentials: 'include',
    })
  }

  async resolveCashout (txHash: string) {
    return this.fetchFromApi(`/cashouts/${txHash}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        resolved: true,
      }),
    })
  }

  async getAllAgreements (): Promise<ApiAgreement[]> {
    return this.fetchFromApi('/agreements')
  }

  async registerPayout (wallet: Wallet, agreement: string, txHash: string) {
    const url = `${this.apiUrl}/payouts/${agreement}/${txHash}`
    const timestamp = Date.now()
    const message = ['PUT', url, '{}', timestamp].join(':')
    const signature = await wallet.signMessage(message)
    const authorization = [signature, wallet.address, timestamp].join(':')
    return fetch(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    }).then(handleApiResponse)
  }

  async getAgreementPayouts (address: ETHAddress): Promise<TransactionHash[]> {
    return this.fetchFromApi(`/payouts/${address}`)
  }

  async getAgreementDetails (address: string): Promise<ApiAgreement> {
    return this.fetchFromApi(`/agreements/${address}`)
  }

  async getContractDetails (address: string): Promise<ApiContract> {
    return this.fetchFromApi(`/contracts/${address}`)
  }

  async getUserDetails (address: string): Promise<ApiUserData> {
    return this.fetchFromApi(`/users/${address}/public`)
  }

  async getUserByPaymentId (id: string): Promise<ApiUserData> {
    return this.fetchFromApi(`/user-payments/${id}/user`)
  }

  async isPaymentEnabled (id: string) {
    return this.fetchFromApi(`/user-payments/isEnabled?id=${id}`)
  }

  async getPayoutAmount (address: ETHAddress, timestamp: Dayjs) {
    const day = timestamp.format('YYYYMMDD')
    const { amount } = await this.fetchFromApi(`/agreements/${address}/payout-predictions?day=${day}`)
    return new CurrencyValue(amount, 'EUR')
  }

  async getContractActivityFor (address: string) {
    return this.fetchFromApi(`/contracts/${address}/activity`)
  }

  async getRedeemCode (campaignId: number, campaignApiKey: string, ETH: string, OWN: string) {
    return this.fetchFromApi(`/campaigns/${campaignId}/redeem-codes`, {
      method: 'POST',
      body: JSON.stringify({ ETH, OWN }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${campaignApiKey}`,
      },
    })
  }

  async connectStripeSession (id: string, amount: string) {
    return this.fetchFromApi('/stripe-session', {
      method: 'POST',
      body: JSON.stringify({ id, amount }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
  }

  async getPaymentData (sessionId: string | null) {
    if (sessionId) {
      return this.fetchFromApi(`/stripe-session/${sessionId}`)
    }
  }

  async findContracts (params: { holder?: string }): Promise<ApiContract[]>
  async findContracts (params: { type: 'ASSET', holder?: string }): Promise<ApiAssetContract[]>
  async findContracts (params: { type: 'AGREEMENT', holder?: string }): Promise<ApiAgreementContract[]>
  async findContracts (params: { type?: string, holder?: string }): Promise<any> {
    const query = [
      params.holder && `holder=${params.holder}`,
      params.type && `type=${params.type}`,
    ].filter(x => x !== undefined).join('&')
    return this.fetchFromApi(`/contracts${query ? '?' : ''}${query}`)
  }

  async getSplitsFor (address: string) {
    return this.fetchFromApi(`/splits/${address}`)
  }

  async getLoans (): Promise<Loan[]> {
    const result = await this.fetchFromApi('/loans', {
      credentials: 'include',
    })
    return result.loans
  }

  async approveLoan (id: number, interestRate: number) {
    return this.fetchFromApi(`/loans/approve/${id}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        interestRate,
      }),
    })
  }

  private async fetchFromApi (...[url, ...args]: Parameters<typeof fetch>) {
    return fetch(`${this.apiUrl}${url}`, ...args).then(handleApiResponse)
  }
}
